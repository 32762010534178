import React, { useEffect } from 'react';
import useScrollFadeIn from '../hooks/useScrollFadeIn';

const About = () => {
  useScrollFadeIn();  // Applying fade-in effect

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
    <div id="about" className="section min-h-screen py-20 bg-gradient-to-t from-gray-800 to-gray-900 flex flex-col items-center opacity-0">
      <h1 className="text-5xl font-bold mb-8">About Me</h1>
      <div className="max-w-6xl grid gap-8 grid-cols-1 md:grid-cols-3">
        
        {/* Developer Card */}
        <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-3xl font-bold mb-4">Developer</h2>
          <p className="text-gray-300 text-lg leading-relaxed">
            As a full-stack developer, I graduated in December 2024 with a Bachelor of Science in Computer Science. I specialize in React, JavaScript, and full-stack web development.
          </p>
        </div>

        {/* Legal Assistant Card */}
        <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-3xl font-bold mb-4">Legal Assistant</h2>
          <p className="text-gray-300 text-lg leading-relaxed">
            I have worked as a legal assistant at Docks Law Firm since August 2021, gaining extensive experience in motion drafting, legal research, and client management.
          </p>
        </div>

        {/* Musician Card */}
        <div className="bg-gray-700 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-3xl font-bold mb-4">Musician</h2>
          <p className="text-gray-300 text-lg leading-relaxed">
            Music has been a passion of mine, and I have pursued it academically with a minor in music, focusing choral performance.
            With the EMU Choir, I have traveled the world and performed in the chorus for Andrea Bocelli twice.
          </p>
        </div>

      </div>
    </div>
  );
};

export default About;